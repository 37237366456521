@import '~antd/dist/antd.less';

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

tr.ant-table-row {
  &.readonly {
    background-color: rgba(128, 128, 128, 0.15);
    td {
      border-color: rgba(128, 128, 128, 0.1);
    }
    &:hover {
      td {
        background-color: rgba(128, 128, 128, 0.2);
      }
    }
  }
}
@primary-color: #1DA57A;